import './recipe-teaser-slider.scss';

import Swiper from 'swiper';
import { Navigation, A11y, Pagination, EffectCards } from 'swiper/modules';

class RecipeTeaserSlider {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-recipe-teaser-slider',
            sliderAttr: 'slider',
            slideAttr: 'slide'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$recipeSlider = $element;
        this.$recipeSliderContainer = this.$recipeSlider.querySelector(`[${this.settings.initAttr}="${this.settings.sliderAttr}"]`);
        this.$recipeSlides = this.$recipeSlider.querySelectorAll(`[${this.settings.initAttr}="${this.settings.slideAttr}"]`);
        this.recipeSlider = null;

        if (this.$recipeSliderContainer && this.$recipeSlides.length > 1) {
            this.initialize();
        }
    }

    initialize () {
        if (!window.mediaMatchHandler[1024].matches) {
            this.initMobileSlider();
        } else {
            if (this.$recipeSlides.length > 2) {
                this.initDesktopSlider();
            }
        }

        this.setEvents();
    }

    setEvents () {
        window.mediaMatchHandler[1024].addEventListener('change', (match) => {
            if (!match.matches) {
                this.destroySlider();
                this.initMobileSlider();
            } else {
                this.destroySlider();
                if (this.$recipeSlides.length > 2) {
                    this.initDesktopSlider();
                }
            }
        });
    }

    initMobileSlider () {
        this.recipeSlider = new Swiper(this.$recipeSliderContainer, {
            modules: [EffectCards, Pagination, A11y],
            speed: 500,
            effect: 'cards',
            cardsEffect: {
                rotate: true,
                perSlideRotate: 2,
                perSlideOffset: 3,
                slideShadows: false
            },
            a11y: {
                prevSlideMessage: 'Vorheriger Slide',
                nextSlideMessage: 'Nächster Slide',
                paginationBulletMessage: 'Springe zu Slide {{index}}',
                firstSlideMessage: 'Dies ist der Erste Slide',
                lastSlideMessage: 'Dies ist der Letzte Slide',
                slideLabelMessage: 'Slide {{index}} von {{slidesLength}}'
            },
            slidesPerView: 'auto',
            centeredSlides: true,
            centeredSlidesBounds: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            on: {
                transitionEnd: (swiper) => {
                    const activeSlideIndex = swiper.activeIndex;
                    const $activeSlide = swiper.slides[activeSlideIndex];
                    const activeTranslateX = swiper.translate;
                    const transformStyle = 'translate3d(' + activeTranslateX + 'px, 0, 0)';
                    $activeSlide.style.transform = transformStyle;
                }
            }
        });
    }

    initDesktopSlider () {
        this.recipeSlider = new Swiper(this.$recipeSliderContainer, {
            modules: [Navigation, A11y],
            speed: 500,
            slidesPerView: 'auto',
            centeredSlides: true,
            centeredSlidesBounds: true,
            setWrapperSize: true,
            observer: true,
            initialSlide: 0,
            a11y: {
                prevSlideMessage: 'Vorheriger Slide',
                nextSlideMessage: 'Nächster Slide',
                paginationBulletMessage: 'Springe zu Slide {{index}}',
                firstSlideMessage: 'Dies ist der Erste Slide',
                lastSlideMessage: 'Dies ist der Letzte Slide',
                slideLabelMessage: 'Slide {{index}} von {{slidesLength}}'
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            }
        });
    }

    destroySlider () {
        if (this.recipeSlider !== null) {
            this.recipeSlider.destroy(true, true);
            this.recipeSlider = null;
        }
    }
}

export { RecipeTeaserSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$recipeSlider = $context.querySelectorAll('[data-recipe-teaser-slider="root"]');
        for (let i = 0; i < $$recipeSlider.length; i++) {
            const $recipeSlider = $$recipeSlider[i];

            $recipeSlider.API = new RecipeTeaserSlider($recipeSlider);
        }
    }
});
